





































































import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import GalleryLightbox from '@/components/GalleryLightbox.vue'
import { GalleryImage } from '@/models/GalleryImage'
import app from '@/store/modules/app'

@Component({
  components: { Main, GalleryLightbox },
  metaInfo() {
    return {
      title: `${app.title} | Engagement Gallery`,
    }
  },
})
export default class EngagementGallery extends Vue {
  index = null
  photos: GalleryImage[] = []
  overlay = false

  get selectedPhoto(): GalleryImage {
    if (this.index === null) {
      return null
    }
    return this.photos[this.index]
  }

  mounted(): void {
    const path = require.context('@/assets/images/gallery', false, /\.jpg$/)
    const photos = path.keys().map(path) as string[]
    const thumbnails = photos.filter((path) => path.includes('thumbnail'))
    const fullsize = photos.filter((path) => !path.includes('thumbnail'))
    thumbnails.sort((a, b) => {
      const aStub = a.split('.')[0].replace('_thumbnail', '')
      const bStub = b.split('.')[0].replace('_thumbnail', '')
      return aStub.localeCompare(bStub)
    })
    for (const thumbnail of thumbnails) {
      const stub = `${thumbnail.split('.')[0].replace('_thumbnail', '')}.`
      const full = fullsize.find((img) => img.includes(stub))
      if (full) {
        this.photos.push({ thumbnail, full })
      }
    }
  }

  selectPhoto(photoIndex: number): void {
    this.index = photoIndex
  }

  previousPhoto(): void {
    let newIndex = this.index - 1
    if (newIndex < 0) {
      newIndex = this.photos.length - 1
    }
    this.selectPhoto(newIndex)
  }

  nextPhoto(): void {
    let newIndex = this.index + 1
    if (newIndex >= this.photos.length) {
      newIndex = 0
    }
    this.selectPhoto(newIndex)
  }
}
