


















































import { GalleryImage } from '@/models/GalleryImage'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Key, KeyCode } from '@/data/enum'

@Component({})
export default class GalleryLightbox extends Vue {
  @Prop({ type: Object, required: true }) readonly selectedPhoto: GalleryImage

  touchEvent = {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 0,
      y: 0,
    },
  }

  next(): void {
    this.$emit('next')
  }

  previous(): void {
    this.$emit('previous')
  }

  close(): void {
    this.$emit('close')
  }

  handleKeydown(event: any): void {
    if (event.key === Key.RightArrow) {
      this.next()
    } else if (event.key === Key.LeftArrow) {
      this.previous()
    } else if (event.keyCode === KeyCode.Escape) {
      this.close()
    }
  }

  handleTouchstart(event: any): void {
    this.touchEvent.start.x = event.changedTouches[0].clientX
    this.touchEvent.start.y = event.changedTouches[0].clientY
  }

  handleTouchend(event: any): void {
    this.touchEvent.end.x = event.changedTouches[0].clientX
    this.touchEvent.end.y = event.changedTouches[0].clientY
    this.handleSwipe()
  }

  handleSwipe(): void {
    const diffX = this.touchEvent.end.x - this.touchEvent.start.x

    const diffXAbs = Math.abs(diffX)
    if (diffXAbs > 50) {
      if (diffX / diffXAbs < 1) {
        this.previous()
      } else {
        this.next()
      }
    }
    this.resetTouchEvent()
  }

  resetTouchEvent(): void {
    this.touchEvent = {
      start: {
        x: 0,
        y: 0,
      },
      end: {
        x: 0,
        y: 0,
      },
    }
  }
}
