/* eslint-disable no-unused-vars */

export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Escape = 27,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
}

export enum Key {
  LeftArrow = 'ArrowLeft',
  RightArrow = 'ArrowRight',
}

export enum InviteTypeId {
  Main = 1,
  DesignatedPlusOne = 2,
  UndesignatedPlusOne = 3,
}
