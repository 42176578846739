var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Main',[_c('div',{staticClass:"d-flex margin-b-6",class:{
      'flex-row align-end': _vm.$vuetify.breakpoint.smAndUp,
      'flex-column text-center': _vm.$vuetify.breakpoint.xs,
    }},[_c('h1',{staticClass:"text-green-dark margin-a-0",class:{
        'font-54': _vm.$vuetify.breakpoint.smAndUp,
        'font-40': _vm.$vuetify.breakpoint.xs,
      },style:({ 'line-height': 1 })},[_vm._v(" Engagement Gallery ")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex grow"}):_vm._e(),_c('h3',{staticClass:"text-green-dark font-bold",class:{
        'margin-t-3 font-14': _vm.$vuetify.breakpoint.xs,
      },style:({ 'line-height': 1 })},[_vm._v(" Photos by "),_c('a',{attrs:{"href":"https://www.kateecarolinephotography.com/","target":"_blank"}},[_vm._v(" Katee + Caroline ")])])]),_c('v-row',_vm._l((_vm.photos),function(photo,photoIndex){return _c('v-col',{key:(photo + "-" + photoIndex),attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-img',{staticClass:"background-gray cursor-pointer",attrs:{"src":photo.thumbnail,"aspect-ratio":"1"},on:{"click":function($event){return _vm.selectPhoto(photoIndex)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"h-full margin-a-0 align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"background-gray lighten-5"}})],1)]},proxy:true}],null,true)})],1)}),1),(_vm.selectedPhoto)?_c('GalleryLightbox',{attrs:{"selected-photo":_vm.selectedPhoto},on:{"previous":_vm.previousPhoto,"next":_vm.nextPhoto,"close":function($event){_vm.index = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }